<div class="login-content">
    <div class="login-logo">
        <a href="/">
            <img class="align-content" src="../../../assets/admin/images/logo_tr3s_color.png" width="200px"alt="">
        </a>
    </div>
    <div class="login-form">
        <form [formGroup]="formLogin" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label>USUARIO</label>
                <input type="email" id="email" formControlName="email" [ngClass]="valClass" (keydown)="clearEmail($event)">
            </div>
            <div class="form-group">
                <label>CONTRASEÑA</label>
                <input type="password" id="password" formControlName="password" [ngClass]="valPassword" (keydown)="clearPass($event)">
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox"> RECORDARME
                </label>
            </div>
            <button type="submit" class="btn btn-login btn-flat m-b-30 m-t-30" [disabled]= "!formLogin.valid">ENTRAR</button>
        </form>
    </div>
</div>
<ngx-spinner></ngx-spinner>
<simple-notifications [options]="options" (onDestroy)="destroyed($event)"></simple-notifications>
