import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpService } from './services/http/http-service.service';
import { AuthGuard } from './guard/auth-guard.service';


import { AppRoutingModule } from './app-routing.module';
import { HttpModule } from '@angular/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppComponent } from './app.component';
import { AdminHeaderComponent } from './layouts/admin-layout/admin-header/admin-header.component';
import { AdminFooterComponent } from './layouts/admin-layout/admin-footer/admin-footer.component';
import { AdminContentComponent } from './layouts/admin-layout/admin-content/admin-content.component';
import { AdminSidebarComponent } from './layouts/admin-layout/admin-sidebar/admin-sidebar.component';
import { AdminCertifiedComponent } from './admin/admin-certified/admin-certified.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { StudentLayoutComponent } from './layouts/student-layout/student-layout.component';
import { AdminStudentComponent } from './admin/admin-student/admin-student.component';
import { DataCertifiedComponent } from './admin/admin-certified/data-certified/data-certified.component';
import { AdminConfigComponent } from './admin/admin-config/admin-config.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { SignoutComponent } from './auth/signout/signout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataSchoolclassComponent } from './admin/admin-config/data-schoolclass/data-schoolclass.component';
import { DataDeleteComponent } from './admin/data-delete/data-delete.component';
import { DataCategoryComponent } from './admin/admin-config/data-category/data-category.component';
import { DataCourseComponent } from './admin/admin-config/data-course/data-course.component';
import { DataStudentComponent } from './admin/admin-student/data-student/data-student.component';
import { AdminTeacherComponent } from './admin/admin-teacher/admin-teacher.component';
import { DataTeacherComponent } from './admin/admin-teacher/data-teacher/data-teacher.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminHeaderComponent,
    AdminFooterComponent,
    AdminContentComponent,
    AdminSidebarComponent,
    AdminCertifiedComponent,
    AdminLayoutComponent,
    StudentLayoutComponent,
    AdminStudentComponent,
    DataCertifiedComponent,
    AdminConfigComponent,
    LoginComponent,
    AuthLayoutComponent,
    SignoutComponent,
    DataSchoolclassComponent,
    DataDeleteComponent,
    DataCategoryComponent,
    DataCourseComponent,
    DataStudentComponent,
    AdminTeacherComponent,
    DataTeacherComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot(),
    NgbModule,
    NgxPaginationModule
  ],
  providers: [HttpService,AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
