import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notification/noti-service.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';

@Component({
  selector: 'app-admin-student',
  templateUrl: './admin-student.component.html',
  styleUrls: ['./admin-student.component.css']
})
export class AdminStudentComponent implements OnInit {
  selData: any = [];
  titleData: string = '';
  componentCode: string = '';
  listStudents:any = [];
  bViewAllStudent:boolean = false;

  p: number = 1;
  collection: any[] = []; 

  constructor(private httpService: HttpService, private spinner: NgxSpinnerService, private notification: NotificationsService, private noti: NotiService) { }
  formStudent = new FormGroup({
    student_name: new FormControl('', Validators.required)
  });

  ngOnInit(): void {
    this.showListStudents();
  }

  studentAction(event:any) {
    this.showListStudents();
  }

  dataDeleted(event:any) {
    if(event === true) {
      this.showListStudents();
    }
  }

  delStudent(student:any) {
    this.selData= [student, 'st', 'ALUMNO', student.name +' '+ student.lastname +' '+ student.surname];
  }

  showListStudents() {
    this.httpService.showStudents().subscribe(
      data => {

        if (data.data.hasOwnProperty('students')) {
          this.listStudents = data.data.students;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showSchoolClass admin-config');
          console.log(error);
        }
      }
    )
  }

  onSubmitStudent() {
    this.httpService.showStudentByName(this.formStudent).subscribe(
      data => {
        if (data.data.hasOwnProperty('students')) {
          this.bViewAllStudent = true;
          this.listStudents = data.data.students;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error onSubmitStudent admin-student');
          console.log(error);
        }
      }
    );
  }


  viewAllStudent() {
    this.bViewAllStudent = false;
    this.showListStudents();
  }

}
