<ng-template #modalDataSchoolClass let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{actionSchoolClass}} GENERACIÓN {{schoolclass.name}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="formData" (ngSubmit)="onSubmit()">
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>NOMBRE</label>
                        <input type="text" id="name" formControlName="name" [ngClass]="valName" (keyup)="clearEmail($event)">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-secondary btn-sm" [disabled]="!formData.valid">Aceptar</button>
            <button type="reset" class="btn btn-danger btn-sm" (click)="modal.dismiss('Cross click')">Salir</button>
        </div>
    </form>
</ng-template>
<button *ngIf="actionSchoolClass==='EDITAR'" id="payment-button" type="button" class="btn btn-sm btn-edit" (click)="open(modalDataSchoolClass)">
    <i class="fa fa-pencil fa-lg" aria-hidden="true"></i> Editar&nbsp;
</button>
<button *ngIf="actionSchoolClass==='ALMACENAR'" class="btn btn_student" (click)="open(modalDataSchoolClass)">AGREGAR GENERACIÓN </button>
<ngx-spinner></ngx-spinner>
<simple-notifications [options]="options" (onDestroy)="destroyed($event)"></simple-notifications>