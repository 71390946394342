import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpService } from '../../services/http/http-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notification/noti-service.service';

@Component({
  selector: 'data-delete',
  templateUrl: './data-delete.component.html',
  styleUrls: ['./data-delete.component.css']
})
export class DataDeleteComponent implements OnInit {

  @Input() objectValue:any=[];
  @Input() componentCode:string='';
  @Input() title:string='';
  @Output() dataDeleted: EventEmitter <boolean> = new EventEmitter <boolean>();
  options:any;

  constructor(private httpService:HttpService, private spinner: NgxSpinnerService, private notification: NotificationsService, private noti: NotiService) { }

  ngOnInit(): void {

  }

  selectDel() {
    switch (this.objectValue[1]){
      case 'sc':
        this.delSchoolClass();
        break;
      case 'ct':
        this.delCategory();
        break;
      case 'cs':
        this.delCourse();
        break;
      case 'st':
        this.delStudent();
        break;
      case 'cr':
        this.delCertified();
        break;
      case 'tc':
        this.delTeacher();
        break;
    }
  }

  delSchoolClass() {
    this.options = this.noti.getOptions();
    this.showSpinner();

    this.httpService.dechoolClass(this.objectValue[0].id).subscribe(
      data=>{
        this.dataDeleted.emit(true);

        setTimeout(()=>{
          this.spinner.hide();
          this.notification.success('Generación eliminada','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.spinner.hide();
        this.notification.error('Error al eliminar generación','Tr3s Psicología y Sexología');
      }
    );
  }

  delCategory() {
    this.options = this.noti.getOptions();
    this.showSpinner();

    this.httpService.delCategory(this.objectValue[0].id).subscribe(
      data=>{
        this.dataDeleted.emit(true);

        setTimeout(()=>{
          this.spinner.hide();
          this.notification.success('Categoría eliminada','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.spinner.hide();
        this.notification.error('Error al eliminar categoría','Tr3s Psicología y Sexología');
      }
    );
  }

  delCourse() {
    this.options = this.noti.getOptions();
    this.showSpinner();

    this.httpService.delCourse(this.objectValue[0].id).subscribe(
      data=>{
        this.dataDeleted.emit(true);

        setTimeout(()=>{
          this.spinner.hide();
          this.notification.success('Curso eliminado','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.spinner.hide();
        this.notification.error('Error al eliminar curso','Tr3s Psicología y Sexología');
      }
    );
  }

  delStudent() {
    this.options = this.noti.getOptions();
    this.showSpinner();

    this.httpService.delStudent(this.objectValue[0].id).subscribe(
      data=>{
        this.dataDeleted.emit(true);

        setTimeout(()=>{
          this.spinner.hide();
          this.notification.success('Alumno eliminado','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.spinner.hide();
        this.notification.error('Error al eliminar alumno','Tr3s Psicología y Sexología');
      }
    );
  }

  delCertified() {
    this.options = this.noti.getOptions();
    this.showSpinner();

    this.httpService.delCertified(this.objectValue[0].id).subscribe(
      data=>{
        this.dataDeleted.emit(true);

        setTimeout(()=>{
          this.spinner.hide();
          this.notification.success('Certificado eliminado','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.spinner.hide();
        this.notification.error('Error al eliminar certificado','Tr3s Psicología y Sexología');
      }
    );
  }

  delTeacher() {
    this.options = this.noti.getOptions();
    this.showSpinner();

    this.httpService.delTeacher(this.objectValue[0].id).subscribe(
      data=>{
        this.dataDeleted.emit(true);

        setTimeout(()=>{
          this.spinner.hide();
          this.notification.success('Instructor eliminado','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.spinner.hide();
        this.notification.error('Error al eliminar instructor','Tr3s Psicología y Sexología');
      }
    );
  }

  showSpinner() {
    this.spinner.show(undefined, {
      bdColor:"rgba(51,51,51,0.8)",
      size:"medium",
      type:"timer",
      color: "#9363cc",
    });
  }

  destroyed(event:any) {
  
  }


}
