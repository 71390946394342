import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css']
})
export class AdminSidebarComponent implements OnInit {
  classMenu: string ='';

  constructor() { }

  ngOnInit(): void {
  }

  assignClass(numMenu=0) {
    switch(numMenu) {
      case 1:
        this.classMenu='active';
        break;
    }
  }

}
