import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-footer',
  templateUrl: './admin-footer.component.html',
  styleUrls: ['./admin-footer.component.css']
})
export class AdminFooterComponent implements OnInit {
  yearPage:any;

  constructor() { }

  ngOnInit(): void {
    this.yearPage = new Date();
    this.yearPage = this.yearPage.getFullYear();
  }

}
