import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { HttpService } from '../../../services/http/http-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../../services/notification/noti-service.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'data-course',
  templateUrl: './data-course.component.html',
  styleUrls: ['./data-course.component.css']
})

export class DataCourseComponent implements OnInit {

  @Input() actionCourse:string ='';
  @Input() course:any =[];
  @Output() courseAction: EventEmitter <boolean> = new EventEmitter <boolean>();

  options: any;
  modalOption: NgbModalOptions = {};
  closeResult: string = '';
  listCategories:any = [];
  valName:any=['is-invalid','form-control'];
  valCategory:any=['is-invalid','form-control'];
  valHours:any=[];

  formData = new FormGroup({
    name: new FormControl('', Validators.required),
    category_id: new FormControl('', Validators.required),
    hours: new FormControl('')
  });

  constructor(private httpService:HttpService, private spinner: NgxSpinnerService, private notification: NotificationsService, private noti: NotiService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.options = this.noti.getOptions();
  }

  open(content:any) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.showCategories();
    this.showCourseData();
  }

  private showCourseData() {
    if (this.actionCourse === 'EDITAR') {
      this.formData.controls['name'].setValue(this.course.name);
      this.formData.controls['category_id'].setValue(this.course.category_id);
      this.formData.controls['hours'].setValue(this.course.hours);
    } else {
      this.formData.controls['category_id'].setValue(-1);
    }

    this.clearData(true);
    this.clearCategory(true);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormData();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormData();
      return 'by clicking on a backdrop';
    } else {
      this.clearFormData();
      return  `with: ${reason}`;
    }
  }

  private clearFormData() {
    this.formData.controls['name'].setValue('');
    this.formData.controls['category_id'].setValue(-1);
    this.formData.controls['hours'].setValue(0);
    this.valName = ['is-invalid','form-control'];
    this.valCategory = ['is-invalid','form-control'];
    this.valHours = ['form-control'];
  }

  clearData(event:any) {
    if (this.formData.controls['name'].value==='') {
      this.valName = ['is-invalid','form-control'];
    } else {
      this.valName = ['is-valid','form-control','form-control-success'];
    }
  }

  clearCategory(event:any) {
    if (this.formData.controls['category_id'].value<0) {
      this.valCategory = ['is-invalid','form-control'];
    } else {
      this.valCategory = ['is-valid','form-control','form-control-success'];
    }
  }

  clearHours(event:any) {
    this.valHours = ['is-valid','form-control','form-control-success'];
  }

  showSpinner() {
    this.spinner.show(undefined, {
      bdColor:"rgba(51,51,51,0.8)",
      size:"medium",
      type:"timer",
      color: "#9363cc",
    });
  }

  destroyed(event:any) {
  
  }

  onSubmit() {
    this.options = this.noti.getOptions();
    this.showSpinner();

    if (this.course.hasOwnProperty('id')) {
      this.editCourse();
    } else {
      this.saveCourse();
    }
  }

  editCourse() {
    this.course.name = this.formData.controls['name'].value;
    this.course.category_id = this.formData.controls['category_id'].value;
    this.course.hours = this.formData.controls['hours'].value;
    this.httpService.editCourse(this.course).subscribe(
      data=>{
        this.courseAction.emit(true);

        setTimeout(()=>{
          this.modalService.dismissAll();
          this.spinner.hide();
          this.notification.success('Curso editado','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.modalService.dismissAll();
        this.spinner.hide();
        this.notification.error('Error al editar curso','Tr3s Psicología y Sexología');
      }
    );
  }

  saveCourse() {
    this.httpService.saveCourse(this.formData).subscribe(
      data=>{
        this.courseAction.emit(true);

        setTimeout(()=>{
          this.modalService.dismissAll();
          this.spinner.hide();
          this.notification.success('Curso almacenado','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.modalService.dismissAll();
        this.spinner.hide();
        this.notification.error('Error al almacenar curso','Tr3s Psicología y Sexología');
      }
    );
  }

  showCategories() {
    this.httpService.showCategories().subscribe(
      data => {
        if (data.data.hasOwnProperty('categories')) {
          this.listCategories = data.data.categories;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showCategory data-course');
          console.log(error);
        }
      }
    )
  }

}
