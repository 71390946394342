<ng-template #modalTeacher let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{actionTeacher}} INSTRUCTOR {{teacher.name}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="formData" (ngSubmit)="onSubmit()">
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="form-group">
                        <label>NOMBRE DEL INSTRUCTOR</label>
                        <input type="text" id="name" formControlName="name" [ngClass]="valName" (keyup)="clearName($event)">
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="form-group">
                        <label>APELLIDO PATERNO</label>
                        <input type="text" id="lastname" formControlName="lastname" [ngClass]="valLastname" (keyup)="clearLastname($event)">
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="form-group">
                        <label>APELLIDO MATERNO</label>
                        <input type="text" id="surname" formControlName="surname" class="form-control">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 col-md-12 col-xs-12">
                    <h6>ASIGNAR CURSOS</h6>
                    <div class="form-group">
                        <label *ngFor="let course of listCourses" class="form_check_label">
                            <input value="{{course.id}}" type="checkbox" class="form-check-input" [checked]="course.selected" (change)="onCheckboxChange($event)">{{course.name}}
                        </label>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-secondary btn-sm" [disabled]="!formData.valid">Aceptar</button>
            <button type="reset" class="btn btn-danger btn-sm" (click)="modal.dismiss('Cross click')">Salir</button>
        </div>
    </form>
</ng-template>
<button *ngIf="actionTeacher=='EDITAR'" id="payment-button" type="button" class="btn btn-sm btn-edit" (click)="open(modalTeacher)">
    <i class="fa fa-pencil fa-lg" aria-hidden="true"></i> Editar&nbsp;
</button>
<button *ngIf="actionTeacher==='ALMACENAR'" class="btn btn_student" (click)="open(modalTeacher)">AGREGAR INSTRUCTOR </button>
<ngx-spinner></ngx-spinner>
<simple-notifications [options]="options" (onDestroy)="destroyed($event)"></simple-notifications>
