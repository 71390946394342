import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { HttpService } from '../../../services/http/http-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../../services/notification/noti-service.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'data-certified',
  templateUrl: './data-certified.component.html',
  styleUrls: ['./data-certified.component.css']
})
export class DataCertifiedComponent implements OnInit {
  @Input() actionCertified:string ='';
  @Input() certified:any =[];
  @Output() certifiedAction: EventEmitter <boolean> = new EventEmitter <boolean>();

  options: any;
  modalOption: NgbModalOptions = {};
  closeResult: string = '';
  valCode: any = ['form-control'];
  valStudent: any = ['form-control'];
  valCourse: any = ['form-control'];
  valTeacher: any = ['form-control'];
  valMark: any = ['form-control'];
  valStartDate: any = ['form-control'];
  valEndDate: any = ['form-control'];
  valSchoolClass: any = ['form-control'];

  pathQr: string = '';

  listStudents:any = [];
  listCourses:any = [];
  listTeachers:any = [];
  listSchoolClass:any = [];

  formData = new FormGroup({
    code: new FormControl('', Validators.required),
    student_id: new FormControl('', Validators.required),
    teacher_id: new FormControl('', Validators.required),
    course_id: new FormControl('', Validators.required),
    start_date: new FormControl('',Validators.required),
    end_date: new FormControl('',Validators.required),
    mark: new FormControl(''),
    schoolclass_id: new FormControl('',Validators.required)
  });

  constructor(private httpService:HttpService, private spinner: NgxSpinnerService, private notification: NotificationsService, private noti: NotiService, private modalService: NgbModal) { }

  ngOnInit(): void {
    
  }
  
  clearCode(event:any) {
    if (this.formData.controls['code'].value === '' || this.formData.controls['code'].value === "NULL") {
      this.valCode = ['is-invalid','form-control'];
    } else{
      this.valCode = ['is-valid','form-control','form-control-success'];
    }

    this.pathQr = environment.pathqr+this.formData.controls['code'].value;
  }

  clearStudent(event: any) {
    if (isNaN(parseInt(this.formData.controls['student_id'].value)) === true) {
      this.valStudent = ['is-invalid','form-control'];
    } else {
      this.valStudent = ['is-valid','form-control','form-control-success'];
    }
  }

  clearCourse(event: any) {
    if (isNaN(parseInt(this.formData.controls['course_id'].value)) === true) {
      this.valCourse = ['is-invalid','form-control'];
      this.listTeachers = [];
      this.valTeacher = ['is-invalid','form-control'];
    } else {
      this.valCourse = ['is-valid','form-control','form-control-success'];
      this.showTeacherByCourse();
    }
  }

  clearTeacher(event: any) {
    if (isNaN(parseInt(this.formData.controls['teacher_id'].value)) === true) {
      this.valTeacher = ['is-invalid','form-control'];
    } else {
      this.valTeacher = ['is-valid','form-control','form-control-success'];
    }
  }

  clearMark(event: any) {
    if (isNaN(parseFloat(this.formData.controls['mark'].value))===true) {
      this.valMark = ['is-invalid','form-control'];
    } else {
      this.valMark = ['is-valid','form-control','form-control-success'];
    }
  }

  clearStartDate(event: any) {
    if (this.formData.controls['start_date'].value === '') {
      this.valStartDate = ['is-invalid','form-control'];
    } else {
      this.valStartDate = ['is-valid','form-control','form-control-success'];
    }
  }

  clearEndDate(event: any) {
    console.log(this.formData.controls['end_date'].value);
    if (this.formData.controls['end_date'].value === '') {
      this.valEndDate = ['is-invalid','form-control'];
    } else {
      this.valEndDate = ['is-valid','form-control','form-control-success'];
    }
  }

  clearSchoolClass(event: any) {
    if (isNaN(parseInt(this.formData.controls['schoolclass_id'].value)) === true) {
      this.valSchoolClass = ['is-invalid','form-control'];
    } else {
      this.valSchoolClass = ['is-valid','form-control','form-control-success'];
    }
  }

  open(content:any) {
    this.options = this.noti.getOptions();
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.showListStudents();
    this.showCourses();
    this.showSchoolClass();
    this.showData();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormData();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormData();
      return 'by clicking on a backdrop';
    } else {
      this.clearFormData();
      return  `with: ${reason}`;
    }
  }

  private showData() {
    if (this.certified.hasOwnProperty('id')) {

      this.pathQr = environment.pathqr+this.certified.code;
      
      this.formData.controls['code'].setValue(this.certified.code);
      this.formData.controls['student_id'].setValue(this.certified.student_id);
      this.formData.controls['course_id'].setValue(this.certified.course_id);
      this.showTeacherByCourse();
      this.formData.controls['start_date'].setValue(this.certified.start_date);
      this.formData.controls['end_date'].setValue(this.certified.end_date);
      this.formData.controls['teacher_id'].setValue(this.certified.teacher_id);
      this.formData.controls['mark'].setValue(this.certified.mark);
      this.formData.controls['schoolclass_id'].setValue(this.certified.school_class_id);
    } else {
      this.clearCode(true);
      this.clearStudent(true);
      this.clearCourse(true);
      this.clearTeacher(true);
      //this.clearMark(true);
      this.clearStartDate(true);
      this.clearEndDate(true);
      this.clearSchoolClass(true);
    }
  }

  private clearFormData() {
    this.formData.controls['code'].setValue('');
    this.formData.controls['student_id'].setValue('');
    this.formData.controls['teacher_id'].setValue('');
    this.formData.controls['course_id'].setValue('');
    this.formData.controls['start_date'].setValue('');
    this.formData.controls['end_date'].setValue('');
    this.formData.controls['mark'].setValue('');
    this.formData.controls['schoolclass_id'].setValue('');
  }

  showSpinner() {
    this.spinner.show(undefined, {
      bdColor:"rgba(51,51,51,0.8)",
      size:"medium",
      type:"timer",
      color: "#9363cc",
    });
  }

  destroyed(event:any) {
  
  }

  onSubmit() {
    this.options = this.noti.getOptions();
    this.showSpinner();

    if (this.certified.hasOwnProperty('id')) {
      this.editCertified();
    } else {
      this.saveCertified();
    }
  }

  showListStudents() {
    this.httpService.showStudents().subscribe(
      data => {
        if (data.data.hasOwnProperty('students')) {
          this.listStudents = data.data.students;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showSchoolClass admin-config');
          console.log(error);
        }
      }
    )
  }

  showCourses() {
    this.httpService.showCourses().subscribe(
      data => {
        if (data.data.hasOwnProperty('courses')) {
          this.listCourses = data.data.courses;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showCourses admin-config');
          console.log(error);
        }
      }
    )
  }

  showTeacherByCourse() {
    let course_id = this.formData.controls['course_id'].value;
    this.httpService.showTeacherByCourse(course_id).subscribe(
      data => {
        if (data.data.hasOwnProperty('teachers')) {
          this.listTeachers = data.data.teachers;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showCourses admin-config');
          console.log(error);
        }
      }
    )
  }

  showSchoolClass() {
    this.httpService.showSchoolClass().subscribe(
      data => {
        if (data.data.hasOwnProperty('schoolclass')) {
          this.listSchoolClass = data.data.schoolclass;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showSchoolClass admin-config');
          console.log(error);
        }
      }
    )
  }

  editCertified() {
    this.certified.code = this.formData.controls['code'].value;
    this.certified.start_date = this.formData.controls['start_date'].value;
    this.certified.end_date = this.formData.controls['end_date'].value;
    this.certified.student_id = this.formData.controls['student_id'].value;
    this.certified.teacher_id = this.formData.controls['teacher_id'].value;
    this.certified.course_id = this.formData.controls['course_id'].value;
    this.certified.mark = this.formData.controls['mark'].value;
    this.certified.school_class_id = this.formData.controls['schoolclass_id'].value;

    this.httpService.editCertified(this.certified).subscribe(
      data=>{
        this.certifiedAction.emit(true);

        setTimeout(()=>{
          this.modalService.dismissAll();
          this.spinner.hide();
          this.notification.success('Certificado actualizado','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.modalService.dismissAll();
        this.spinner.hide();
        this.notification.error('Error al almacenar actualizado','Tr3s Psicología y Sexología');
      }
    );
  }

  saveCertified() {
    this.httpService.saveCertified(this.formData).subscribe(
      data=>{
        this.certifiedAction.emit(true);

        setTimeout(()=>{
          this.modalService.dismissAll();
          this.spinner.hide();
          this.notification.success('Certificado almacenado','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        this.modalService.dismissAll();
        this.spinner.hide();
        if(parseInt(error.status) === 410) {
          this.notification.error('El número de certificado ya se agregó anteriormente');
        } else {
          this.notification.error('Error al almacenar certificado','Tr3s Psicología y Sexología');
        }
      }
    );
  }

}
