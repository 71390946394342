import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { HttpService } from '../../services/http/http-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notification/noti-service.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loading = false;
  showError = false;
  options: any;
  valClass: any = '';
  valPassword: any = '';

  formLogin = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  constructor(private fb: FormBuilder, private router: Router, private httpService: HttpService, private spinner: NgxSpinnerService, private notification: NotificationsService, private noti: NotiService) {}

  ngOnInit(): void {
    this.options = this.noti.getOptions();
    this.valClass = ['is-invalid','form-control'];
    this.valPassword = ['is-invalid','form-control'];

  }

  clearEmail(event:any) {
    this.valClass = ['is-valid','form-control','form-control-success'];
  }

  clearPass(event:any) {
    this.valPassword = ['is-valid','form-control','form-control-success'];
  }

  onSubmit() {
    let username = this.formLogin.controls['email'].value;
    let password = this.formLogin.controls['password'].value;

    this.loading =true;

    this.showSpinner();

    this.httpService.checkLoginAccess(username, password).subscribe(
      data => {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("name", data.data.name);

        this.notification.success('Tr3s Psicología y Sexología', 'Bienvenido');

        setTimeout(()=>{
          this.loading = false;
          this.router.navigate(['/']);
          this.spinner.hide();
        }, 3000);
      },
      error => {
        console.log(error);
        this.loading = false;
        this.showError = true;
        this.spinner.hide();
        this.notification.error('Tr3s Psicología y Sexología', 'Error en usuario o contraseña');
      }
    ) 
  }

  showSpinner() {
    this.spinner.show(undefined, {
      bdColor:"rgba(51,51,51,0.8)",
      size:"medium",
      type:"timer",
      color: "#9363cc",
    });
  }

  destroyed(event:any) {
  
  }

}
