<div class="row">
    <!-- /# column -->
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h2 class="title">CERTIFICADO</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="input-group-btn"><data-certified [actionCertified]="'ALMACENAR'" (certifiedAction)="certifiedAction($event)"></data-certified></div>
                        <div *ngIf="bViewAllCertifieds==true" class="input-group-btn"><button class="btn btn_student btn-view" (click)="viewAllCertifieds()">VER TODOS</button>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 search">
                        <form [formGroup]="formCertified" (ngSubmit)="onSubmitCertified()">
                            <div class="input-group">
                                <input type="text" id="certified_query" formControlName="certified_query" placeholder="Buscar certificado o alumno..." class="form-control">
                                <div class="input-group-btn"><button class="btn btn-search" [disabled]="!formCertified.valid">BUSCAR </button></div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <table *ngIf="listCertifieds.length>0" class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">CERTIFICADO</th>
                                    <th scope="col">ALUMNO</th>
                                    <th scope="col">CURSO</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let certified of listCertifieds | paginate:{itemsPerPage: 8, currentPage:p}">
                                    <th scope="row">{{certified.code}}</th>
                                    <td>{{certified.name}} {{certified.lastname}} {{certified.surname}}</td>
                                    <td>{{certified.course_name}}</td>
                                    <td>
                                        <data-certified [actionCertified]="'EDITAR'" (certifiedAction)="certifiedAction($event)" [certified]="certified"></data-certified>

                                        <button id="payment-button" type="button" class="btn btn-sm btn-delete" data-toggle="modal" data-target="#modalDelSchoolClass" (click)="delCertified(certified)">
                                            <i class="fa fa-trash fa-lg" aria-hidden="true"></i> Borrar &nbsp;
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <div *ngIf="listCertifieds.length>0" id="pagination">
                            <pagination-controls (pageChange)="p=$event"></pagination-controls>
                        </div>  
                        <h2 *ngIf="listCertifieds.length === 0">No se encontraron datos</h2>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <!-- /# column -->
</div>

<data-delete [objectValue]="selData" [componentCode]="componentCode" [title]="titleData" (dataDeleted)="dataDeleted($event)"></data-delete>
