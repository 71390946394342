<ng-template #modalCertified let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{actionCertified}} CERTIFICADO {{certified.code}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="formData" (ngSubmit)="onSubmit()">
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="form-group">
                        <label>NÚMERO DE CERTIFICADO</label>
                        <input type="text" id="code" formControlName="code" [ngClass]="valCode" (keyup)="clearCode($event)">
                    </div>
                </div>
                <div class="col-lg-8 col-md-6 col-xs-12">
                    <div class="form-group">
                        <label>ALUMNO</label>
                        <select id="student_id" formControlName="student_id" [ngClass]="valStudent" (change)="clearStudent($event)">
                            <option value="">-Seleccionar-</option>
                            <option *ngFor="let student of listStudents" value="{{student.id}}">{{student.name}} {{student.lastname}} {{student.surname}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-xs-12">
                    <div class="form-group">
                        <label>CURSO</label>
                        <select id="course_id" formControlName="course_id" [ngClass]="valCourse" (change)="clearCourse($event)">
                            <option value="">-Seleccionar-</option>
                            <option *ngFor="let course of listCourses" value="{{course.id}}">{{course.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12">
                    <div class="form-group">
                        <label>INSTRUCTOR</label>
                        <select id="teacher_id" formControlName="teacher_id" [ngClass]="valTeacher" (change)="clearTeacher($event)">
                            <option value="">-Seleccionar-</option>
                            <option *ngFor="let teacher of listTeachers" value="{{teacher.id}}">{{teacher.name}} {{teacher.lastname}} {{teacher.surname}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="form-group">
                        <label>ACREDITACIÓN</label>
                        <input type="number" id="mark" formControlName="mark" [ngClass]="valMark" (keyup)="clearMark($event)">
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="form-group">
                        <label>FECHA DE INICIO</label>
                        <input type="date" id="start_date" formControlName="start_date" [ngClass]="valStartDate" (change)="clearStartDate($event)">
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="form-group">
                        <label>FECHA DE TERMINACIÓN</label>
                        <input type="date" id="end_date" formControlName="end_date" [ngClass]="valEndDate" (change)="clearEndDate($event)">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-xs-12">
                    <div class="form-group">
                        <label>GENERACIÓN</label>
                        <select id="schoolclass_id" formControlName="schoolclass_id" [ngClass]="valSchoolClass" (change)="clearSchoolClass($event)">
                            <option value="">-Seleccionar-</option>
                            <option *ngFor="let sc of listSchoolClass" value="{{sc.id}}">{{sc.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-xs-12">
                    <div class="form-group">
                        <label>QR Code Liga</label>
                        <input type="text" [ngClass]="'form-control'" value="{{pathQr}}">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-secondary btn-sm" [disabled]="!formData.valid">Aceptar</button>
            <button type="reset" class="btn btn-danger btn-sm" (click)="modal.dismiss('Cross click')">Salir</button>
        </div>
    </form>
</ng-template>
<button *ngIf="actionCertified=='EDITAR'" id="payment-button" type="button" class="btn btn-sm btn-edit" (click)="open(modalCertified)">
    <i class="fa fa-pencil fa-lg" aria-hidden="true"></i> Editar&nbsp;
</button>
<button *ngIf="actionCertified==='ALMACENAR'" class="btn btn_student" (click)="open(modalCertified)">AGREGAR CERTIFICADO </button>
<ngx-spinner></ngx-spinner>
<simple-notifications [options]="options" (onDestroy)="destroyed($event)"></simple-notifications>
