import { Injectable } from '@angular/core';
import {Http, Response, Headers, RequestOptions, ResponseOptions} from "@angular/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
//import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http:Http, private router: Router) { }

  checkLoginAccess(email:string, password: string): Observable <any> {
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let options = new RequestOptions({ headers: headers });
      let body = {
          "email":  email,
          "password":  password
      };

      return this.http.post(environment.pathapi + 'login', body, options).pipe(map((res: Response) => res.json()));
  }

  showCertifieds() {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
      
    return this.http.post(environment.pathapi + 'showcertifieds', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  showSchoolClass() {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
      
    return this.http.post(environment.pathapi + 'showschoolclass', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  showStudents() {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
      
    return this.http.post(environment.pathapi + 'showstudents', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  showTeachers() {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
      
    return this.http.post(environment.pathapi + 'showteachers', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  showCategories() {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
      
    return this.http.post(environment.pathapi + 'showcategories', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  showCourses() {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
      
    return this.http.post(environment.pathapi + 'showcourses', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  showCourseByName(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.controls['course_name'].value);
      
    return this.http.post(environment.pathapi + 'showcoursebyname', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  showCategoryByName(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.controls['category_name'].value);
      
    return this.http.post(environment.pathapi + 'showcategorybyname', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  showSchoolClassByName(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.controls['schoolclass_name'].value);
      
    return this.http.post(environment.pathapi + 'showschoolclassbyname', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  showStudentByName(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.controls['student_name'].value);
      
    return this.http.post(environment.pathapi + 'showstudentbyname', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  showTeacherByCourse(id=0) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });

    let body = {
        "course_id":  id
    };
      
    return this.http.post(environment.pathapi + 'showteacherbycourse', body, options).pipe(map((res: Response) => res.json())); 
  }

  showCertifiedByNumberStudent(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.controls['certified_query'].value);

    return this.http.post(environment.pathapi + 'showcertifiedbynumberstudent', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  showCourseByTeacher(id=0) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let body = {
      "teacher_id":  id
    };

    return this.http.post(environment.pathapi + 'showcoursebyteacher', body, options).pipe(map((res: Response) => res.json())); 
  }

  showTeacherByName(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.controls['teacher_name'].value);

    return this.http.post(environment.pathapi + 'showteacherbyname', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  dechoolClass(id=0) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });

    let body = {
      "schoolclass_id":  id
    };

    return this.http.post(environment.pathapi + 'deleteschoolclass', body, options).pipe(map((res: Response) => res.json()));
  }

  delCategory(id=0) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });

    let body = {
      "category_id":  id
    };

    return this.http.post(environment.pathapi + 'deletecategory', body, options).pipe(map((res: Response) => res.json()));
  }

  delCourse(id=0) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });

    let body = {
      "course_id":  id
    };

    return this.http.post(environment.pathapi + 'deletecourse', body, options).pipe(map((res: Response) => res.json()));
  }

  delStudent(id=0) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });

    let body = {
      "student_id":  id
    };

    return this.http.post(environment.pathapi + 'deletestudent', body, options).pipe(map((res: Response) => res.json()));
  }

  delCertified(id=0) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });

    let body = {
      "certified_id":  id
    };

    return this.http.post(environment.pathapi + 'deletecoursestudent', body, options).pipe(map((res: Response) => res.json()));
  }

  delTeacher(id=0) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });

    let body = {
      "teacher_id":  id
    };

    return this.http.post(environment.pathapi + 'deleteteacher', body, options).pipe(map((res: Response) => res.json()));
  }

  editSchoolClass(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.name);
    formDataComponente.append('schoolclass_id', component.id );
      
    return this.http.post(environment.pathapi + 'editschoolclass', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  editCategory(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.name);
    formDataComponente.append('category_id', component.id );
      
    return this.http.post(environment.pathapi + 'editcategory', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  
  }

  editCourse(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.name);
    formDataComponente.append('category_id', component.category_id );
    formDataComponente.append('hours', component.hours);
    formDataComponente.append('course_id', component.id );
      
    return this.http.post(environment.pathapi + 'editcourse', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  editStudent(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();

    formDataComponente.append('name', component.name);
    formDataComponente.append('lastname', component.lastname);
    formDataComponente.append('surname', component.surname);
    formDataComponente.append('code', component.code);
    formDataComponente.append('email', component.email);
    formDataComponente.append('path_picture', component.path_picture);
    formDataComponente.append('student_id', component.id );
      
    return this.http.post(environment.pathapi + 'editstudent', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  editCertified(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('code', component.code);
    formDataComponente.append('student_id', component.student_id);
    formDataComponente.append('course_id', component.course_id);
    formDataComponente.append('teacher_id', component.teacher_id);
    formDataComponente.append('mark', component.mark);
    formDataComponente.append('start_date', component.start_date);
    formDataComponente.append('end_date', component.end_date);
    formDataComponente.append('school_class_id', component.school_class_id);
    formDataComponente.append('course_student_id', component.id);
          
    return this.http.post(environment.pathapi + 'editcoursestudent', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  editTeacher(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();

    formDataComponente.append('name', component.name);
    formDataComponente.append('lastname', component.lastname);
    formDataComponente.append('surname', component.surname);
    formDataComponente.append('course', component.course);
    formDataComponente.append('teacher_id', component.id);
      
    return this.http.post(environment.pathapi + 'editteacher', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  saveSchoolClass(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.controls['name'].value);
      
    return this.http.post(environment.pathapi + 'saveschoolclass', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  saveCategory(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.controls['name'].value);
      
    return this.http.post(environment.pathapi + 'savecategory', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  saveCourse(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.controls['name'].value);
    formDataComponente.append('category_id', component.controls['category_id'].value);
    formDataComponente.append('hours', component.controls['hours'].value);
      
    return this.http.post(environment.pathapi + 'savecourse', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  saveStudent(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();

    formDataComponente.append('name', component.controls['name'].value);
    formDataComponente.append('lastname', component.controls['lastname'].value);
    formDataComponente.append('surname', component.controls['surname'].value);
    formDataComponente.append('code', component.controls['code'].value);
    formDataComponente.append('email', component.controls['email'].value);
    formDataComponente.append('path_picture', component.get('fileSource').value);
      
    return this.http.post(environment.pathapi + 'savestudent', formDataComponente, options).pipe(map((res: Response) => res.json())); 

  }

  saveTeacher(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();

    formDataComponente.append('name', component.controls['name'].value);
    formDataComponente.append('lastname', component.controls['lastname'].value);
    formDataComponente.append('surname', component.controls['surname'].value);
    formDataComponente.append('course', component.controls['course'].value);
      
    return this.http.post(environment.pathapi + 'saveteacher', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  saveCertified(component:any=[]) {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
    formDataComponente.append('code', component.controls['code'].value);
    formDataComponente.append('student_id', component.controls['student_id'].value);
    formDataComponente.append('course_id', component.controls['course_id'].value);
    formDataComponente.append('teacher_id', component.controls['teacher_id'].value);
    formDataComponente.append('mark', component.controls['mark'].value);
    formDataComponente.append('start_date', component.controls['start_date'].value);
    formDataComponente.append('end_date', component.controls['end_date'].value);
    formDataComponente.append('school_class_id', component.controls['schoolclass_id'].value);

      
    return this.http.post(environment.pathapi + 'savecoursestudent', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  sendToLogin() {
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("name");
    this.router.navigate(["/login"]);
  }

}
