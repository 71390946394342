<!-- Left Panel -->
<aside id="left-panel" class="left-panel">
    <nav class="navbar navbar-expand-sm navbar-default">
        <div id="main-menu" class="main-menu collapse navbar-collapse">
            <ul class="nav navbar-nav">
                <li class="">
                    <a href="/" (click)="assignClass(1)"><i class="menu-icon fa fa-certificate"></i>CERTIFICADO </a>
                </li>

                <li class="">
                    <a href="/admin-student"><i class="menu-icon fa fa-graduation-cap"></i>ALUMNOS</a>
                </li>

                <li class="">
                    <a href="/admin-teacher"><i class="menu-icon fa fa-street-view"></i>INSTRUCTORES</a>
                </li>

                <li class="">
                    <a href="/admin-config"><i class="menu-icon fa fa-cogs"></i>UTILERÍAS</a>
                </li>

                <li>
                    <a href="/logout"><i class="menu-icon fa fa-sign-out"></i>SALIR</a>
                </li>
            </ul>
        </div><!-- /.navbar-collapse -->
    </nav>
</aside>
<!-- /#left-panel -->
