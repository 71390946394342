import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from '../app/layouts/admin-layout/admin-layout.component';
import { AdminCertifiedComponent } from '../app/admin/admin-certified/admin-certified.component';
import { AdminStudentComponent } from '../app/admin/admin-student/admin-student.component';
import { AdminConfigComponent } from '../app/admin/admin-config/admin-config.component';
import { AuthLayoutComponent } from '../app/layouts/auth-layout/auth-layout.component';
import { LoginComponent } from '../app/auth/login/login.component';
import { SignoutComponent } from '../app/auth/signout/signout.component';
import { AdminTeacherComponent } from '../app/admin/admin-teacher/admin-teacher.component';

import { AuthGuard } from './guard/auth-guard.service';


const routes: Routes = [
  {
    path:'',
    component:AdminLayoutComponent,
    children:[
      { path: '', redirectTo: '/admin', pathMatch:'full'  },
      { path: 'admin', component:AdminCertifiedComponent, canActivate: [AuthGuard] },
      { path: 'admin-student', component:AdminStudentComponent, canActivate: [AuthGuard] },
      { path: 'admin-teacher', component:AdminTeacherComponent, canActivate: [AuthGuard] },
      { path: 'admin-config', component:AdminConfigComponent, canActivate: [AuthGuard]},
      { path: 'logout', component: SignoutComponent, canActivate: [AuthGuard] },
    ]
  },
  {
    path:'',
    component: AuthLayoutComponent,
    children:[
      { path: 'login', component:LoginComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
