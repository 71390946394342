import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { HttpService } from '../../../services/http/http-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../../services/notification/noti-service.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'data-student',
  templateUrl: './data-student.component.html',
  styleUrls: ['./data-student.component.css']
})
export class DataStudentComponent implements OnInit {
  @Input() actionStudent:string ='';
  @Input() student:any =[];
  @Output() studentAction: EventEmitter <boolean> = new EventEmitter <boolean>();

  options: any;
  modalOption: NgbModalOptions = {};
  closeResult: string = '';
  valName: any = [];
  valLastname: any = [];
  valCode: any = [];
  valPicture: any = [];
  validationError: any = [];

  formData = new FormGroup({
    name: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    surname: new FormControl(''),
    code: new FormControl('', Validators.required),
    email: new FormControl(''),
    path_picture: new FormControl('', Validators.required),
    fileSource: new FormControl('')
  });

  constructor(private httpService:HttpService, private spinner: NgxSpinnerService, private notification: NotificationsService, private noti: NotiService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.options = this.noti.getOptions();
    /*this.valName = ['is-invalid','form-control'];
    this.valLastname = ['is-invalid','form-control'];
    this.valCode = ['is-invalid','form-control'];
    this.valPicture = ['is-invalid','form-control'];*/
  }

  clearName(event:any) {
    if (this.formData.controls['name'].value === '') {
      this.valName = ['is-invalid','form-control'];
    } else{
      this.valName = ['is-valid','form-control','form-control-success'];
    }
  }

  clearLastname(event:any) {
    if (this.formData.controls['lastname'].value === '') {
      this.valLastname = ['is-invalid','form-control'];
    } else{
      this.valLastname = ['is-valid','form-control','form-control-success'];
    }
  }

  clearCode(event:any) {
    if (this.formData.controls['code'].value === '') {
      this.valCode = ['is-invalid','form-control'];
    } else{
      this.valCode = ['is-valid','form-control','form-control-success'];
    }
  }

  setErrorAlert() {
    this.valName = "form-control";
    this.valCode = "form-control";
    this.valLastname = "form-control";
    this.valPicture = "form-control";
    this.validationError = [];

    if(!this.formData.controls['name'].value) {
      this.valName = ['is-invalid','form-control'];
      this.validationError.push('Debe capturar un código SKU');
    }

    if(!this.formData.controls['lastname'].value) {
      this.valLastname = ['is-invalid','form-control'];
      this.validationError.push('Debe capturar un código SKU');
    }

    if(!this.formData.controls['code'].value) {
      this.valCode = ['is-invalid','form-control'];
      this.validationError.push('Debe capturar un código SKU');
    }

    this.valPicture = ['is-invalid','form-control'];
    
  }

  open(content:any) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.showStudentData();
    /*this.clearName(true);
    this.clearCode(true);
    this.clearLastname(true);*/
    this.setErrorAlert();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormData();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormData();
      return 'by clicking on a backdrop';
    } else {
      this.clearFormData();
      return  `with: ${reason}`;
    }
  }

  private clearFormData() {
    this.formData.controls['name'].setValue('');
    this.formData.controls['lastname'].setValue('');
    this.formData.controls['surname'].setValue('');
    this.formData.controls['code'].setValue('');
    this.formData.controls['email'].setValue('');
    this.formData.controls['path_picture'].setValue('');

  }

  private showStudentData() {
    if (this.actionStudent==='EDITAR') {
      this.formData.controls['name'].setValue(this.student.name);
      this.formData.controls['lastname'].setValue(this.student.lastname);
      this.formData.controls['surname'].setValue(this.student.surname);
      this.formData.controls['code'].setValue(this.student.code);
      this.formData.controls['email'].setValue(this.student.email);

      this.clearName(true);
      this.clearCode(true);
      this.clearLastname(true);
    }
  }

  showSpinner() {
    this.spinner.show(undefined, {
      bdColor:"rgba(51,51,51,0.8)",
      size:"medium",
      type:"timer",
      color: "#9363cc",
    });
  }

  destroyed(event:any) {
  
  }

  onSubmit() {
    this.options = this.noti.getOptions();
    this.showSpinner();

    if (this.student.hasOwnProperty('id')) {
      this.editStudent();
    } else {
      this.saveStudent();
    }
  }

  private editStudent() {
    this.student.name = this.formData.controls['name'].value;
    this.student.lastname = this.formData.controls['lastname'].value;
    this.student.surname = this.formData.controls['surname'].value;
    this.student.code = this.formData.controls['code'].value;
    this.student.email = this.formData.controls['email'].value;
    this.student.path_picture = this.formData.get('fileSource')?.value;

    this.httpService.editStudent(this.student).subscribe(
      data=>{
        this.studentAction.emit(true);

        setTimeout(()=>{
          this.modalService.dismissAll();
          this.spinner.hide();
          this.notification.success('Alumno editado','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.modalService.dismissAll();
        this.spinner.hide();
        this.notification.error('Error al editar alumno','Tr3s Psicología y Sexología');
      }
    );
  }

  private saveStudent() {
    this.httpService.saveStudent(this.formData).subscribe(
      data=>{
        this.studentAction.emit(true);
        setTimeout(()=>{
          this.modalService.dismissAll();
          this.spinner.hide();
          this.notification.success('Alumno almacenado','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.modalService.dismissAll();
        this.spinner.hide();
        this.notification.error('Error al almacenar alumno','Tr3s Psicología y Sexología');
      }
    );
  }

  onFileChange(event: any) {
    this.setErrorAlert();
    this.valPicture = ['is-invalid','form-control'];
    if(event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formData.patchValue({
        fileSource: file
      });
      this.valPicture = ['is-valid','form-control','form-control-success'];
    } else {
      this.validationError.push('Debe elegir una imagen válida');
    }
  }

}
