import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';

@Component({
  selector: 'admin-certified',
  templateUrl: './admin-certified.component.html',
  styleUrls: ['./admin-certified.component.css']
})
export class AdminCertifiedComponent implements OnInit {
  listCertifieds: any = [];
  certified: any = [];
  selData: any = [];
  componentCode: string = '';
  titleData: string = '';
  bViewAllCertifieds: boolean = false;

  formCertified = new FormGroup({
    certified_query: new FormControl('', Validators.required)
  });

  p: number = 1;
  collection: any[] = []; 

  constructor(private httpService: HttpService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.showCertifieds();
  }

  showCertifieds() {
    this.httpService.showCertifieds().subscribe(
      data => {
        if (data.data.hasOwnProperty('certifieds')) {
          this.listCertifieds = data.data.certifieds;
        }
      },
      error => {
        if(error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showCertifieds admin-certified');
          console.log(error);
        }
      }
    )
  }

  editCertified(certified:any=[]) {
    this.certified = certified;
  }

  certifiedAction(event:any) {
    if (event === true) {
      this.showCertifieds();
    }
  }

  delCertified(certified:any) {
    this.selData= [certified, 'cr', 'CERTIFICADO', certified.code];
  }

  dataDeleted(event:any) {
    if (event === true) {
      this.showCertifieds();
    }
  }

  onSubmitCertified() {
    this.bViewAllCertifieds = true;
    this.httpService.showCertifiedByNumberStudent(this.formCertified).subscribe(
      data => {
        if (data.data.hasOwnProperty('certifieds')) {
          this.listCertifieds = data.data.certifieds;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showCourses admin-config');
          console.log(error);
        }
      }
    );
  }

  viewAllCertifieds() {
    this.showCertifieds();
    this.formCertified.controls['certified_query'].setValue('');
    this.bViewAllCertifieds = false;
  }
  
}
