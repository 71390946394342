<admin-sidebar></admin-sidebar>
<div id="right-panel" class="right-panel">
    <admin-header></admin-header>
    <div class="content">
        <!-- Animated -->
        <div class="animated fadeIn">
            <router-outlet></router-outlet>
        </div>
        <!-- .animated -->
    </div>
    <div class="clearfix"></div>
    <admin-footer></admin-footer>
</div>

