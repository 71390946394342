<div #content class="modal fade" id="modalDelSchoolClass" tabindex="-1" role="dialog" aria-labelledby="mediumModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 *ngIf="objectValue.length > 0 " class="modal-title" id="mediumModalLabel">ELIMINAR {{objectValue[2]}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label *ngIf="objectValue.length > 0 " class="control-label mb-1">Deseas eliminar: {{objectValue[3]}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary btn-sm" (click)="selectDel()" data-dismiss="modal">Aceptar</button>
                <button type="reset" class="btn btn-danger btn-sm" data-dismiss="modal">Salir</button>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
<simple-notifications [options]="options" (onDestroy)="destroyed($event)"></simple-notifications>
