<div class="row">
    <!-- /# column -->
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h2 class="title">ALUMNOS</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="input-group-btn"><data-student [actionStudent]="'ALMACENAR'" (studentAction)="studentAction($event)"></data-student></div>
                        <div *ngIf="bViewAllStudent==true" class="input-group-btn"><button class="btn btn_student btn-view" (click)="viewAllStudent()">VER TODOS</button>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 search">
                        <form [formGroup]="formStudent" (ngSubmit)="onSubmitStudent()">
                            <div class="input-group">
                                <input type="text" id="student_name" formControlName="student_name" placeholder="Buscar alumno..." class="form-control">
                                <div class="input-group-btn"><button type="submit" class="btn btn-search" [disabled]="!formStudent.valid">BUSCAR </button></div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <table *ngIf="listStudents.length > 0" class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">MATRÍCULA</th>
                                    <th scope="col">ALUMNO</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let student of listStudents | paginate:{itemsPerPage: 8, currentPage:p}">
                                    <th scope="row">{{student.code}}</th>
                                    <td>{{student.name}} {{student.lastname}} {{student.surname}}</td>
                                    <td>
                                        <data-student [actionStudent]="'EDITAR'" [student]="student" (studentAction)="studentAction($event)"></data-student>
                                        <button id="payment-button" type="button" class="btn btn-sm btn-delete" data-toggle="modal" data-target="#modalDelSchoolClass" (click)="delStudent(student)">
                                            <i class="fa fa-trash fa-lg" aria-hidden="true"></i> Borrar &nbsp;
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div *ngIf="listStudents.length > 0" id="pagination">
                            <pagination-controls (pageChange)="p=$event"></pagination-controls>
                        </div>

                        <h2 *ngIf="listStudents.length === 0">No se encontraron datos</h2>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <!-- /# column -->
</div>
<data-delete [objectValue]="selData" [componentCode]="componentCode" [title]="titleData" (dataDeleted)="dataDeleted($event)"></data-delete>
