import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { HttpService } from '../../services/http/http-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notification/noti-service.service';
import {NgxPaginationModule} from 'ngx-pagination';

@Component({
  selector: 'admin-config',
  templateUrl: './admin-config.component.html',
  styleUrls: ['./admin-config.component.css']
})
export class AdminConfigComponent implements OnInit {
  listSchoolClass:any=[];
  listCategories:any=[];
  listCourses:any=[];
  selData: any = [];
  titleData: string = '';
  componentCode: string = '';
  actionSchoolClass: string = '';
  bviewAllCourses: boolean = false;
  bviewAllCategories: boolean = false;
  bviewAllSC: boolean = false;

  p: number = 1;
  collection: any[] = []; 

  p2: number = 1;
  collection2: any[] = []; 

  p3: number = 1;
  collection3: any[] = [];

  constructor(private fb: FormBuilder, private router: Router, private httpService: HttpService, private spinner: NgxSpinnerService, private notification: NotificationsService, private noti: NotiService) { }

  formCourse = new FormGroup({
    course_name: new FormControl('', Validators.required)
  });

  formCategory = new FormGroup({
    category_name: new FormControl('', Validators.required)
  });

  formSchoolClass = new FormGroup({
    schoolclass_name: new FormControl('', Validators.required)
  });

  ngOnInit(): void {
    this.showSchoolClass();
    this.showCategory();
    this.showCourses();
  }

  showSpinner() {
    this.spinner.show(undefined, {
      bdColor:"rgba(51,51,51,0.8)",
      size:"medium",
      type:"timer",
      color: "#9363cc",
    });
  }
  
  showSchoolClass() {
    this.httpService.showSchoolClass().subscribe(
      data => {
        if (data.data.hasOwnProperty('schoolclass')) {
          this.listSchoolClass = data.data.schoolclass;

        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showSchoolClass admin-config');
          console.log(error);
        }
      }
    )
  }

  showCategory() {
    this.httpService.showCategories().subscribe(
      data => {
        if (data.data.hasOwnProperty('categories')) {
          this.listCategories = data.data.categories;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showCategory admin-config');
          console.log(error);
        }
      }
    )
  }

  showCourses() {
    this.httpService.showCourses().subscribe(
      data => {
        if (data.data.hasOwnProperty('courses')) {
          this.listCourses = data.data.courses;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showCourses admin-config');
          console.log(error);
        }
      }
    )
  }

  delSchoolClass(schoolclass:any) {
    this.selData= [schoolclass, 'sc', 'GENERACIÓN', schoolclass.name];
    this.componentCode = 'sc';
  }

  delCategory(category:any) {
    this.selData= [category, 'ct', 'CATEGORÍA', category.name];
    this.componentCode = 'ct';
  }

  delCourse(course:any) {
    this.selData= [course, 'cs', 'CURSO', course.name];
    this.componentCode = 'cs';
  }

  dataDeleted(event=false) {
    if (event==true) {

      switch(this.componentCode) {
        case 'sc':
          this.showSchoolClass();
          break;
        case 'ct':
          this.showCategory();
          break;
        case 'cs':
          this.showCourses();
          break;
      }
    }
  }

  schoolClassAction(event=false) {
    if (event==true) {
      this.showSchoolClass();
    }
  }

  categoryAction(event=false) {
    if (event==true) {
      this.showCategory();
    }
  }

  courseAction(event=false) {
    if (event==true) {
      this.showCourses();
    }
  }


  onSubmitCourse() {
    this.bviewAllCourses = true;
    this.httpService.showCourseByName(this.formCourse).subscribe(
      data => {
        if (data.data.hasOwnProperty('courses')) {
          this.listCourses = data.data.courses;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showCourses admin-config');
          console.log(error);
        }
      }
    );
  }

  onSubmitCategory() {
    this.bviewAllCategories = true;
    this.httpService.showCategoryByName(this.formCategory).subscribe(
      data => {
        if (data.data.hasOwnProperty('categories')) {
          this.listCategories = data.data.categories;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showCourses admin-config');
          console.log(error);
        }
      }
    );
  }

  onSubmitSchoolClass() {
    this.bviewAllSC=true;
    this.httpService.showSchoolClassByName(this.formSchoolClass).subscribe(
      data => {
        if (data.data.hasOwnProperty('schoolclass')) {
          this.listSchoolClass = data.data.schoolclass;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showCourses admin-config');
          console.log(error);
        }
      }
    );
  }

  viewAllCourse() {
    this.showCourses();
    this.formCourse.controls['course_name'].setValue('');
    this.bviewAllCourses = false;
  }

  viewAllCategories() {
    this.showCategory();
    this.formCategory.controls['category_name'].setValue('');
    this.bviewAllCategories = false;
  }

  viewAllSchoolClass() {
    this.showSchoolClass();
    this.formSchoolClass.controls['schoolclass_name'].setValue('');
    this.bviewAllSC = false;
  }

}
