<div class="row">
    <!-- /# column -->
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="custom-tab">
                            <nav>
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a class="nav-item nav-link active" id="custom-nav-course-tab" data-toggle="tab" href="#custom-nav-course" role="tab" aria-controls="custom-nav-course" aria-selected="true">CURSOS</a>
                                    <a class="nav-item nav-link" id="custom-nav-category-tab" data-toggle="tab" href="#custom-nav-category" role="tab" aria-controls="custom-nav-category" aria-selected="false">CATEGORÍA</a>
                                    <a class="nav-item nav-link" id="custom-nav-grade-tab" data-toggle="tab" href="#custom-nav-grade" role="tab" aria-controls="custom-nav-grade" aria-selected="false">GENERACIÓN</a>
                                </div>
                            </nav>
                            <div class="tab-content pl-3 pt-2" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="custom-nav-course" role="tabpanel" aria-labelledby="custom-nav-course-tab">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="input-group-btn"><data-course [actionCourse]="'ALMACENAR'" (courseAction)="courseAction($event)"></data-course></div>
                                            <div *ngIf="bviewAllCourses==true" class="input-group-btn"><button class="btn btn_student btn-view" (click)="viewAllCourse()">VER TODOS</button>
                                            </div>
                                        </div>
                    
                                        <div class="col-lg-12 col-md-12 search">
                                            <form [formGroup]="formCourse" (ngSubmit)="onSubmitCourse()">
                                                <div class="input-group">
                                                    <input type="text" id="course_name" formControlName="course_name" placeholder="Buscar curso..." class="form-control">
                                                    <div class="input-group-btn"><button type="submit" class="btn btn-search" [disabled]="!formCourse.valid">BUSCAR </button></div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <table *ngIf="listCourses.length>0" class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">CURSO</th>
                                                        <th scope="col">CATEGORÍA</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let course of listCourses | paginate:{itemsPerPage: 8, currentPage:p}">
                                                        <th scope="row">{{course.name}}</th>
                                                        <td>{{course.category_name}}</td>
                                                        <td>
                                                            <data-course [actionCourse]="'EDITAR'" [course]="course" (courseAction)="courseAction($event)"></data-course>
                    
                                                            <button id="payment-button" type="button" class="btn btn-sm btn-delete" data-toggle="modal" data-target="#modalDelSchoolClass" (click)="delCourse(course)">
                                                                <i class="fa fa-trash fa-lg" aria-hidden="true"></i> Borrar &nbsp;
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                            <div *ngIf="listCourses.length>0" id="pagination">
                                                <pagination-controls (pageChange)="p=$event"></pagination-controls>
                                            </div>
                                            <h2 *ngIf="listCourses.length === 0">No se encontraron datos</h2>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="custom-nav-category" role="tabpanel" aria-labelledby="custom-nav-category-tab">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="input-group-btn"><data-category [actionCategory]="'ALMACENAR'" (categoryAction)="categoryAction($event)"></data-category></div>
                                            <div *ngIf="bviewAllCategories==true" class="input-group-btn"><button class="btn btn_student btn-view" (click)="viewAllCategories()">VER TODOS</button>
                                            </div>
                                        </div>
                    
                                        <div class="col-lg-12 col-md-12 search">
                                            <form [formGroup]="formCategory" (ngSubmit)="onSubmitCategory()">
                                                <div class="input-group">
                                                    <input type="text" id="category_name" formControlName="category_name" placeholder="Buscar categoría..." class="form-control">
                                                    <div class="input-group-btn"><button type="submit" class="btn btn-search" [disabled]="!formCategory.valid">BUSCAR </button></div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <table *ngIf="listCategories.length > 0" class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">CATEGORÍA</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let category of listCategories | paginate:{itemsPerPage: 8, currentPage:p2}">
                                                        <th scope="row">{{category.name}}</th>
                                                        <td>
                                                            <data-category [actionCategory]="'EDITAR'" [category]="category" (categoryAction)="categoryAction($event)"></data-category>
                    
                                                            <button id="payment-button" type="button" class="btn btn-sm btn-delete" data-toggle="modal" data-target="#modalDelSchoolClass" (click)="delCategory(category)">
                                                                <i class="fa fa-trash fa-lg" aria-hidden="true"></i> Borrar &nbsp;
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div *ngIf="listCategories.length>0" id="pagination">
                                                <pagination-controls (pageChange)="p2=$event"></pagination-controls>
                                            </div>
                                            <h2 *ngIf="listCategories.length === 0">No se encontraron datos</h2>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="custom-nav-grade" role="tabpanel" aria-labelledby="custom-nav-grade-tab">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="input-group-btn"><data-schoolclass [actionSchoolClass]="'ALMACENAR'" (schoolClassAction)="schoolClassAction($event)"></data-schoolclass></div>
                                            <div *ngIf="bviewAllSC==true" class="input-group-btn"><button class="btn btn_student btn-view" (click)="viewAllSchoolClass()">VER TODOS</button>
                                            </div>
                                        </div>
                    
                                        <div class="col-lg-12 col-md-12 search">
                                            <form [formGroup]="formSchoolClass" (ngSubmit)="onSubmitSchoolClass()">
                                                <div class="input-group">
                                                    <input type="text" id="schoolclass_name" formControlName="schoolclass_name" placeholder="Buscar generación..." class="form-control">
                                                    <div class="input-group-btn"><button type="submit" class="btn btn-search" [disabled]="!formSchoolClass.valid">BUSCAR </button></div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <table *ngIf="listSchoolClass.length > 0" class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">GENERACIÓN</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let sc of listSchoolClass | paginate:{itemsPerPage: 8, currentPage:p3}">
                                                        <th scope="row">{{sc.name}}</th>
                                                        <td>
                                                            <data-schoolclass [actionSchoolClass]="'EDITAR'" [schoolclass]="sc" (schoolClassAction)="schoolClassAction($event)"></data-schoolclass>
                                                            <button id="payment-button" type="button" class="btn btn-sm btn-delete" data-toggle="modal" data-target="#modalDelSchoolClass" (click)="delSchoolClass(sc)">
                                                                <i class="fa fa-trash fa-lg" aria-hidden="true"></i> Borrar &nbsp;
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                            <div *ngIf="listSchoolClass.length>0" id="pagination">
                                                <pagination-controls (pageChange)="p3=$event"></pagination-controls>
                                            </div>
                                            <h2 *ngIf="listSchoolClass.length === 0">No se encontraron datos</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <!-- /# column -->
</div>

<data-delete [objectValue]="selData" [componentCode]="componentCode" [title]="titleData" (dataDeleted)="dataDeleted($event)"></data-delete>
