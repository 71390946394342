import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray
} from '@angular/forms';
import { HttpService } from '../../../services/http/http-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../../services/notification/noti-service.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'data-teacher',
  templateUrl: './data-teacher.component.html',
  styleUrls: ['./data-teacher.component.css']
})
export class DataTeacherComponent implements OnInit {
  @Input() actionTeacher:string ='';
  @Input() teacher:any =[];
  @Output() teacherAction: EventEmitter <boolean> = new EventEmitter <boolean>();

  options: any;
  modalOption: NgbModalOptions = {};
  closeResult: string = '';
  valName: any = ['is-invalid','form-control'];
  valLastname: any = ['is-invalid','form-control'];
  listCourses: any = [];
  listSelected: any = [];

  formData = new FormGroup({
    name: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    surname: new FormControl(''),
    course: new FormArray([])
  });

  constructor(private httpService:HttpService, private spinner: NgxSpinnerService, private notification: NotificationsService, private noti: NotiService, private modalService: NgbModal) { }

  ngOnInit(): void {
    
  }

  open(content:any) {
    this.options = this.noti.getOptions();
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.setData();

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormData();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormData();
      return 'by clicking on a backdrop';
    } else {
      this.clearFormData();
      return  `with: ${reason}`;
    }
  }

  private clearFormData() {
    const course: FormArray = this.formData.get('course') as FormArray;

    this.formData.controls['name'].setValue('');
    this.formData.controls['lastname'].setValue('');
    this.formData.controls['surname'].setValue('');
    while (course.length !== 0) {
      course.removeAt(0);
    }
  }

  clearName(event:any) {
    if (this.formData.controls['name'].value === '') {
      this.valName = ['is-invalid','form-control'];
    } else{
      this.valName = ['is-valid','form-control','form-control-success'];
    }
  }

  clearLastname(event:any) {
    if (this.formData.controls['lastname'].value === '') {
      this.valLastname = ['is-invalid','form-control'];
    } else{
      this.valLastname = ['is-valid','form-control','form-control-success'];
    }
  }

  showSpinner() {
    this.spinner.show(undefined, {
      bdColor:"rgba(51,51,51,0.8)",
      size:"medium",
      type:"timer",
      color: "#9363cc",
    });
  }

  destroyed(event:any) {
  
  }

  onSubmit() {
    this.options = this.noti.getOptions();
    this.showSpinner();

    if (this.teacher.hasOwnProperty('id')) {
      this.editTeacher();
    } else {
      this.saveTeacher();
    }
  }

  setData() {
    if (this.teacher.hasOwnProperty('id')) {
      this.formData.controls['name'].setValue(this.teacher.name);
      this.formData.controls['lastname'].setValue(this.teacher.lastname);
      this.formData.controls['surname'].setValue(this.teacher.surname);

      this.showCourseByTeacher();
    } else {
      this.showCourses();
    }
    this.clearName(true);
    this.clearLastname(true);
  }

  editTeacher() {
    this.teacher.name = this.formData.controls['name'].value;
    this.teacher.lastname = this.formData.controls['lastname'].value;
    this.teacher.surname = this.formData.controls['surname'].value;
    this.teacher.course = this.formData.controls['course'].value;

    this.httpService.editTeacher(this.teacher).subscribe(
      data => {
        if (data.data.success == true) {
          this.teacherAction.emit(true);
          setTimeout(()=>{
            this.modalService.dismissAll();
            this.spinner.hide();
            this.notification.success('Instructor editado','Tr3s Psicología y Sexología');
          }, 3000);
        }
      },
      error => {
        if (error.status == 401) {
        } else {
          this.modalService.dismissAll();
          this.spinner.hide();
          console.log('error editTeacher data-teacher');
          this.notification.error('Error al editar instructor','Tr3s Psicología y Sexología');
        }
      }
    );
  }

  showCourseByTeacher() {
    this.httpService.showCourseByTeacher(this.teacher.id).subscribe(
      data => {
        if (data.data.hasOwnProperty('courses')) {
          let listSelected = data.data.courses;
          const course: FormArray = this.formData.get('course') as FormArray;

          data.data.courses.forEach(function (value:any) {
            course.push(new FormControl(value.id));
          });

          this.showCourses(listSelected);
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showTeachersByCourse data-teacher');
          console.log(error);
        }
      }
    )
  }

  saveTeacher() {
    this.httpService.saveTeacher(this.formData).subscribe(
      data => {
        if (data.data.hasOwnProperty('teacher')) {
          this.teacherAction.emit(true);
          setTimeout(()=>{
            this.modalService.dismissAll();
            this.spinner.hide();
            this.notification.success('Instructor almacenado','Tr3s Psicología y Sexología');
          }, 3000);
        }
      },
      error => {
        if (error.status == 401) {
        } else {
          this.modalService.dismissAll();
          this.spinner.hide();
          console.log('error showCourses admin-config');
          this.notification.error('Error al almacenar instructor','Tr3s Psicología y Sexología');
        }
      }
    );
  }

  showCourses(arrayCourses: any = []) {
    this.httpService.showCourses().subscribe(
      data => {
        if (data.data.hasOwnProperty('courses')) {
          
          if(arrayCourses.length > 0) {
            
            data.data.courses.forEach(function (value1:any) {
              value1.selected = false;
              arrayCourses.forEach(function (value2:any) {
                if (value1.id === value2.id) {
                  value1.selected = true;
                }
              });
            });
          }
          this.listCourses = data.data.courses;

        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showCourses admin-config');
          console.log(error);
        }
      }
    )
  }

  onCheckboxChange(event:any) {
    const course: FormArray = this.formData.get('course') as FormArray;

    if (event.target.checked) {
      course.push(new FormControl(event.target.value));
    } else {
      let i: number = 0;
      course.controls.forEach((item) => {
        if (item.value == event.target.value) {
          course.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

}
