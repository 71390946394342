import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../services/notification/noti-service.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';

@Component({
  selector: 'admin-teacher',
  templateUrl: './admin-teacher.component.html',
  styleUrls: ['./admin-teacher.component.css']
})
export class AdminTeacherComponent implements OnInit {
  selData: any = [];
  titleData: string = '';
  componentCode: string = '';
  listTeachers:any = [];
  bViewAllTeachers:boolean = false;

  p: number = 1;
  collection: any[] = []; 

  formTeacher = new FormGroup({
    teacher_name: new FormControl('', Validators.required)
  });

  constructor(private httpService: HttpService, private spinner: NgxSpinnerService, private notification: NotificationsService, private noti: NotiService) { }

  ngOnInit(): void {
    this.showListTeachers();
  }

  showListTeachers() {
    this.httpService.showTeachers().subscribe(
      data => {

        if (data.data.hasOwnProperty('teachers')) {
          this.listTeachers = data.data.teachers;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error showListTeachers admin-teacher');
          console.log(error);
        }
      }
    )
  }

  onSubmitTeacher() {
    this.bViewAllTeachers = true;
    this.httpService.showTeacherByName(this.formTeacher).subscribe(
      data => {
        if (data.data.hasOwnProperty('teachers')) {
          this.listTeachers = data.data.teachers;
        }
      },
      error => {
        if (error.status == 401) {
          this.httpService.sendToLogin();
        } else {
          console.log('error onSubmitTeacher admin-teacher');
          console.log(error);
        }
      }
    );
  }

  viewAllTeachers() {
    this.bViewAllTeachers = false;
    this.showListTeachers();
  }

  teacherAction(event: any) {
    this.showListTeachers();
  }

  delTeacher(teacher:any) {
    this.selData= [teacher, 'tc', 'INSTRUCTOR', teacher.name +' '+ teacher.lastname +' '+ teacher.surname];
  }

  dataDeleted(event:any) {
    if(event === true) {
      this.showListTeachers();
    }
  }

}
