<!-- Footer -->
<footer class="site-footer">
    <div class="footer-inner">
        <div class="row">
            <div class="col-sm-12">
                Copyright. Todos los Derechos Reservados @{{yearPage}}- TR3S PSICOLOGIA Y SEXOLOGÍA, Powered by Centelica
            </div>
            
        </div>
    </div>
</footer>
<!-- /.site-footer -->