import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { HttpService } from '../../../services/http/http-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationsService } from 'angular2-notifications';
import { NotiService } from '../../../services/notification/noti-service.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'data-category',
  templateUrl: './data-category.component.html',
  styleUrls: ['./data-category.component.css']
})
export class DataCategoryComponent implements OnInit {
  @Input() actionCategory:string ='';
  @Input() category:any =[];
  @Output() categoryAction: EventEmitter <boolean> = new EventEmitter <boolean>();

  options: any;
  modalOption: NgbModalOptions = {};
  closeResult: string = '';
  valName: any = ['is-invalid','form-control'];

  formData = new FormGroup({
    name: new FormControl('', Validators.required)
  });

  constructor(private httpService:HttpService, private spinner: NgxSpinnerService, private notification: NotificationsService, private noti: NotiService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.options = this.noti.getOptions();
    this.valName = ['is-invalid','form-control'];
  }

  open(content:any) {
    this.modalOption.backdrop = 'static';
    this.modalOption.centered = true;
    this.modalOption.keyboard = false;
    this.modalOption.size = 'lg';
    const modalRef = this.modalService.open(content, this.modalOption).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.clearFormData();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.clearFormData();
      return 'by clicking on a backdrop';
    } else {
      this.clearFormData();
      return  `with: ${reason}`;
    }
  }

  private clearFormData() {
    this.formData.controls['name'].setValue('');
    this.valName = ['is-invalid','form-control'];
  }

  clearData(event:any) {
    if (this.formData.controls['name'].value==='') {
      this.valName = ['is-invalid','form-control'];
    } else {
      this.valName = ['is-valid','form-control','form-control-success'];
    }
  }

  showSpinner() {
    this.spinner.show(undefined, {
      bdColor:"rgba(51,51,51,0.8)",
      size:"medium",
      type:"timer",
      color: "#9363cc",
    });
  }

  destroyed(event:any) {
  
  }

  onSubmit() {
    this.options = this.noti.getOptions();
    this.showSpinner();

    if (this.category.hasOwnProperty('id')) {
      this.editCategory();
    } else {
      this.saveCategory();
    }
  }

  editCategory() {
    this.category.name = this.formData.controls['name'].value;
    this.httpService.editCategory(this.category).subscribe(
      data=>{
        this.categoryAction.emit(true);

        setTimeout(()=>{
          this.modalService.dismissAll();
          this.spinner.hide();
          this.notification.success('Categoría editada','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.modalService.dismissAll();
        this.spinner.hide();
        this.notification.error('Error al editar categoría','Tr3s Psicología y Sexología');
      }
    );
  }

  saveCategory() {
    this.httpService.saveCategory(this.formData).subscribe(
      data=>{
        this.categoryAction.emit(true);

        setTimeout(()=>{
          this.modalService.dismissAll();
          this.spinner.hide();
          this.notification.success('Categoría almacenada','Tr3s Psicología y Sexología');
        }, 3000);
      },
      error=>{
        console.log(error);
        this.modalService.dismissAll();
        this.spinner.hide();
        this.notification.error('Error al almacenar categoría','Tr3s Psicología y Sexología');
      }
    );
  }
}
