import { Component, OnInit } from '@angular/core';
import { Router, Route } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})
export class SignoutComponent implements OnInit {

  constructor(private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.signout();
  }

  signout() {
    this.showSpinner();
    localStorage.removeItem("user_info");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("name");
    localStorage.removeItem("token");

    setTimeout(()=>{
      this.router.navigate(['/login']);
      this.spinner.hide();
    }, 3000);
  }

  showSpinner() {
    this.spinner.show(undefined, {
      bdColor:"rgba(51,51,51,0.8)",
      size:"medium",
      type:"timer",
      color: "#ff7e2e",
    });
  }

}
